<template>
  <div class="creation-service-name">
    <el-form
      ref="nameForm"
      :model="nameForm"
      :rules="nameFormRules"
      label-width="200px"
      style="margin-left: 0px; margin-top: 0px"
    >
      <el-form-item label="创建名称" prop="name">
        <el-input
          v-model="nameForm.name"
          style="width: 500px"
          ref="cursorInput"
          placeholder="2-18个字"
        ></el-input>
      </el-form-item>
      <el-form-item label="行业分类" prop="category_id">
        <!-- <el-cascader
          style="width: 500px"
          v-model="nameForm.category_id"
          :options="icList"
          :props="categoryProps"
        >
        </el-cascader> -->
        <el-select
          style="width: 500px"
          v-model="nameForm.category_id"
          placeholder="请选择行业分类"
        >
          <el-option
            v-for="item in icList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="重复提问次数" prop="repeat_ask_num">
        <el-input-number
          :controls="false"
          :precision="0"
          :min="1"
          :max="999999999999"
          v-model="nameForm.repeat_ask_num"
          style="width: 500px"
          placeholder="回答错误重新提问的次数"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="等待时长" prop="wait_time">
        <el-input-number
          :controls="false"
          :precision="0"
          :min="1"
          :max="999999999999"
          v-model="nameForm.wait_time"
          style="width: 478px; margin-right: 5px"
          placeholder="超过等待时长机器人将下线，下线机器人不会在提出问题"
        ></el-input-number
        >秒
      </el-form-item>
      <el-form-item label="访问间隔" prop="visit_interval">
        <el-input-number
          :controls="false"
          :precision="0"
          :min="1"
          :max="999999999999"
          v-model="nameForm.visit_interval"
          style="width: 478px; margin-right: 5px"
          placeholder="每个机器人客户访问间隔时间"
        ></el-input-number
        >秒
      </el-form-item>
      <el-form-item label="平均响应时间" prop="response_time">
        <el-input-number
          :controls="false"
          :precision="0"
          :min="1"
          :max="999999999999"
          v-model="nameForm.response_time"
          style="width: 478px; margin-right: 5px"
          placeholder="客户提问商家回答的时间"
        ></el-input-number
        >秒
      </el-form-item>
      <el-form-item label="响应时长分值比例" prop="score_prop">
        <el-input-number
          :controls="false"
          :precision="0"
          :min="0"
          :max="100"
          v-model="nameForm.score_prop"
          style="width: 478px; margin-right: 5px"
          placeholder="超出响应时间将扣减的分数比例"
        ></el-input-number
        >%
      </el-form-item>
      <el-form-item label="客服场景" prop="scene">
        <el-input
          type="textarea"
          v-model="nameForm.scene"
          style="width: 500px"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="违禁词" prop="banned_word">
        <el-input
          type="textarea"
          v-model="nameForm.banned_word"
          style="width: 500px"
          placeholder="违禁词以#分割"
        ></el-input>
      </el-form-item>
        <el-form-item label="智能客服问答处理任务背景" prop="task_background">
            <el-input
                type="textarea"
                v-model="nameForm.task_background"
                style="width: 500px"
            ></el-input>
        </el-form-item>
        <el-form-item label="智能客服问答处理任务要求" prop="tack_demands">
            <el-input
                type="textarea"
                v-model="nameForm.tack_demands"
                style="width: 500px"
            ></el-input>
        </el-form-item>
        <el-form-item label="客户异议处理任务背景" prop="exception_task_background">
            <el-input
                type="textarea"
                v-model="nameForm.exception_task_background"
                style="width: 500px"
            ></el-input>
        </el-form-item>
        <el-form-item label="客户异议处理任务要求" prop="exception_tack_demands">
            <el-input
                type="textarea"
                v-model="nameForm.exception_tack_demands"
                style="width: 500px"
            ></el-input>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createStepOne",
  props: ["editData"],
  data() {
    return {
      nameForm: {
        name: "",
        category_id: "",
        repeat_ask_num: void 0,
        wait_time: void 0,
        visit_interval: void 0,
        response_time: void 0,
        score_prop: void 0,
        scene: "",
        banned_word: "",
          task_background: '',
          tack_demands: '',
          exception_task_background: '',
          exception_tack_demands: '',
      },
      icList: [],
      categoryProps: { value: "id", label: "name", children: "children" },
      nameFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 18,
            message: "长度在 2 到 18 个字符",
            trigger: "blur",
          },
        ],
        category_id: [
          { required: true, message: "请选择行业分类", trigger: "change" },
        ],
        repeat_ask_num: [
          { required: true, message: "请输入重复提问次数", trigger: "blur" },
        ],
        wait_time: [
          { required: true, message: "请输入等待时长", trigger: "blur" },
        ],
        visit_interval: [
          { required: true, message: "请输入访问间隔", trigger: "blur" },
        ],
        response_time: [
          { required: true, message: "请输入平均响应时间", trigger: "blur" },
        ],
          task_background: [
              { required: true, message: "请输入智能客服问答处理任务背景", trigger: "blur" },
              { message: "长度为1-1000个字符",min: 1, max: 1000, trigger: "blur" },
          ],
          tack_demands: [
              { required: true, message: "请输入智能客服问答处理任务要求", trigger: "blur" },
              { message: "长度为1-1000个字符",min: 1, max: 1000, trigger: "blur" },
          ],
          exception_task_background: [
              { required: true, message: "请输入客户异议处理任务背景", trigger: "blur" },
              { message: "长度为1-1000个字符",min: 1, max: 1000, trigger: "blur" },
          ],
          exception_tack_demands: [
              { required: true, message: "请输入客户异议处理任务要求", trigger: "blur" },
              { message: "长度为1-1000个字符",min: 1, max: 1000, trigger: "blur" },
          ],
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      let time = setInterval(() => {
        if (this.editData) {
          clearInterval(time);
          this.getEditData();
        }
      }, 500);
    }
    this.getKeFuGetIC();
  },
  methods: {
    // 获取编辑数据
    getEditData() {
      this.nameForm.name = this.editData.name;
      this.nameForm.category_id = this.editData.category_id;
      this.nameForm.repeat_ask_num = this.editData.repeat_ask_num;
      this.nameForm.wait_time = this.editData.wait_time;
      this.nameForm.visit_interval = this.editData.visit_interval;
      this.nameForm.response_time = this.editData.response_time;
      this.nameForm.score_prop = this.editData.score_prop
        ? this.editData.score_prop
        : void 0;
      this.nameForm.scene = this.editData.scene;
      this.nameForm.banned_word = this.editData.banned_word;
      this.nameForm.task_background = this.editData.task_background;
      this.nameForm.tack_demands = this.editData.tack_demands;
      this.nameForm.exception_task_background = this.editData.exception_task_background;
      this.nameForm.exception_tack_demands = this.editData.exception_tack_demands;
    },
    // 获取行业分类
    getKeFuGetIC() {
      this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
        if (res.code === 200) {
          this.icList = res.data;
          // this.nameForm.category_id = this.icList[0].id
        } else {
          this.$message.warning(res.msg);
          this.$router.go(-1);
        }
      });
    },
    confirmForm() {
      this.$refs["nameForm"].validate((valid) => {
        if (valid) {
          this.$emit("stepActiveAdd", this.nameForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.creation-service-name {
  margin-top: 26px;
  margin-left: 66px;
}
::v-deep .el-input-number {
  .el-input__inner {
    text-align: left;
  }
}
</style>
